<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <div>
      <p-form
        @submit="onSubmit"
        v-slot="{ invalid }"
      >
        <div class="c-text-subtitle ui-mb-4">
          Faça login com sua conta Estratégia
        </div>

        <!-- Email -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col"
            rules="required|email"
            v-slot="{ errors }"
            name="Email"
          >
            <p-input
              v-model="user.email"
              data-cy="loginField"
              label="Email*"
              name="loginField"
              :error-message="errors[0]"
              type="email"
            />
          </validation-provider>
        </div>

        <!-- Senha / Esqueci minha senha -->
        <div class="c-row ui-mb-8">
          <!-- Senha -->
          <validation-provider
            class="c-col-2 sm:c-col"
            rules="required|min:5"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Senha é obrigatória',
              min: 'A senha precisa ter no mínimo 5 caracteres'
            }"
          >
            <input-password
              v-model="user.password"
              data-cy="passwordField"
              label="Senha*"
              name="passwordField"
              :error-message="errors[0]"
              :disable-dafault-state="true"
            />
          </validation-provider>

          <!-- Esqueci minha senha -->
          <div class="c-col-2 sm:c-col">
            <div class="ui-h-input ui-flex ui-items-center">
              <router-link
                :to="{ name: 'esqueci-senha', query: { ...$route.query, email: user.email } }"
                data-cy="forgotPasswordLink"
                class="c-text-hyperlink c-text-gray"
              >
                Esqueci minha senha
              </router-link>
            </div>
          </div>
        </div>

        <template v-if="showSocialLogin">
          <!-- Social Login Título -->
          <div class="c-row ui-mb-2">
            <div class="c-col c-d-flex c-center">
              <span class="c-text-b2 c-text-gray-300">Ou continue com:</span>
            </div>
          </div>

          <!-- Social Login Botões -->
          <div class="c-row ui-mb-8">
            <div class="c-col">
              <separator />
            </div>

            <div class="c-d-flex">
              <social-login-button provider="facebook" />
              <social-login-button provider="google" />
              <social-login-button provider="apple" />
            </div>

            <div class="c-col">
              <separator />
            </div>
          </div>
        </template>

        <div class="c-row">
          <!-- Botão Continuar -->
          <div class="c-col sm:c-col-1/2">
            <vue-recaptcha
              v-if="isRecaptchaRequired && !recaptchaToken"
              ref="recaptcha"
              :sitekey="$env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY"
              :load-recaptcha-script="true"
              @verify="response => setRecaptchaToken(response)"
              @expired="() => clearRecaptchaToken()"
              @error="() => clearRecaptchaToken()"
            />
            <c-button
              v-else
              :disabled="invalid"
              data-cy="registerButton"
              size="lg"
              type="submit"
              class="ui-w-full"
              :loading="isFormSubmitting"
            >
              Continuar
              <icon
                name="next"
                class="c-icon--right"
              />
            </c-button>
          </div>
        </div>
      </p-form>

      <template v-if="showSignup">
        <separator />

        <div class="c-row">
          <div class="c-col">
            <div class="c-text-gray">
              <span class="c-text-b2">Ainda não tem conta? </span>
              <router-link
                :to="{ name: 'cadastro', query: { ...$route.query } }"
                data-cy="cadastroLink"
                class="c-text-hyperlink"
              >
                Faça o cadastro
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import InputPassword from '@/components/input-password';
import PInput from '@/components/p-input';
import PForm from '@/components/p-form';
import Separator from '@/components/separator';
import SocialLoginButton from '@/components/p-social-login-button';
import GenericModal from '@/modals/generic';
import { notAuthenticatedError, notAuthorizedError, requestCanceledError, unconfirmedEmailError, userNotFoundError } from '@/utils/errors';
import { ValidationProvider } from 'vee-validate';
import { LOGOUT_REASON_KEY, LOGOUT_REASON_MESSAGES } from '@/consts';
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    SocialLoginButton,
    PInput,
    CButton,
    Icon,
    InputPassword,
    Separator,
    ValidationProvider,
    PForm,
    VueRecaptcha,
  },
  data: () => {
    return {
      user: {
        email: '',
        password: '',
      },
      isFormSubmitting: false,
      isRecaptchaRequired: !!localStorage.getItem('last_login_failed'),
      recaptchaToken: localStorage.getItem('recaptcha_token') || '',
      showSocialLogin: false,
      showSignup: false,
    };
  },
  mounted () {
    const email = this.$route.query.email;
    if (email) {
      this.user.email = email.trim();
    }

    const hideSocial = this.getBoolParam('hide-social');
    if (!hideSocial) {
      this.$socialLogin.someActive().then((someActive) => {
        this.showSocialLogin = someActive;
      });
    }

    const hideSignup = this.getBoolParam('hide-signup');
    if (!hideSignup) {
      this.showSignup = true;
    }

    const logoutReason = sessionStorage.getItem(LOGOUT_REASON_KEY);
    if (logoutReason) {
      this.showModalLogoutReason(logoutReason);
    }
    sessionStorage.removeItem(LOGOUT_REASON_KEY);
  },
  methods: {
    getBoolParam(name) {
      try {
        const val = JSON.parse(this.$route.query[name]);
        return !!val;
      } catch (_) {
        return false;
      }
    },
    async onSubmit() {
      this.isFormSubmitting = true;
      try {
        await this.$services.accounts.login(this.user.email, this.user.password, this.recaptchaToken);
        this.$services.events.notifyManualLogin();

        this.$router.push({
          name: 'dados-complementares',
          query: this.$route.query,
        });
        localStorage.removeItem('last_login_failed');
      } catch (e) {
        if (e === requestCanceledError) {
          return;
        }
        if (e === unconfirmedEmailError) {
          this.$router.push({
            name: 'login-confirme-seu-email',
            query: {
              ...this.$route.query,
              email: this.user.email,
            },
          });
        } else if (e === notAuthenticatedError || e === userNotFoundError) {
          this.$toast.show({
            type: 'error',
            text: 'Email ou senha inválidos.',
            timeout: 0,
          });
          localStorage.setItem('last_login_failed', true);
          this.isRecaptchaRequired = true;
          if (this.recaptchaToken) {
            this.clearRecaptchaToken();
          }
        } else if (e === notAuthorizedError) {
          this.$toast.show({
            type: 'error',
            text: 'Falha de acesso. Contate o suporte.',
            timeout: 0,
          });
        } else if (localStorage.getItem('last_login_failed')) {
          this.$toast.show({
            type: 'error',
            text: 'Muitas tentativas foram feitas, tente novamente mais tarde.',
            timeout: 0,
          });
        } else {
          throw e;
        }
      } finally {
        this.isFormSubmitting = false;
      }
    },
    showModalLogoutReason(logoutReason) {
      const message = LOGOUT_REASON_MESSAGES[logoutReason];
      if (!message) return;

      this.$modal.show({
        component: GenericModal,
        overlayOpacity: 0.5,
        props: {
          title: '',
          subTitle: message,
          type: 'error',
          confirmButton: {
            label: 'Fechar',
            onClick: () => this.$modal.hide(),
          },
        },
      });
    },
    setRecaptchaToken (token) {
      this.recaptchaToken = token;
      localStorage.setItem('recaptcha_token', token);
    },
    clearRecaptchaToken () {
      this.recaptchaToken = '';
      localStorage.removeItem('recaptcha_token');
    },
  },
};
</script>
